<template>
  <!-- 221228 DefaultLayout.vueから.config-drawerタグを移植 -->
  <div class="config-drawer">
    <div class="config-content" :class="{bigger: page == 'term', middle: page == 'accountTransfer', smaller: page == 'edit'}">
      <SettingsMenu v-if="page == 'menu'" @close-window="onCloseWindow" @switch-page="onSwitchPage" />
      <SettingsEdit v-if="page == 'edit'" @close-window="onSwitchPage" />
      <SettingsTerm v-if="page == 'term'" @check-agree="onSwitchPage" />
      <SettingsHowto v-if="page == 'howto'" @switch-page="onSwitchPage" />
      <SettingsAccount v-if="page == 'accountTransfer'" @switch-page="onSwitchPage" />
    </div>
  </div>
</template>

<script>
import SettingsMenu from './SettingsMenu'
import SettingsEdit from './Edit'
import SettingsTerm from './Terms'
import SettingsHowto from './Howto'
import SettingsAccount from './Account'
import { mapGetters } from 'vuex'

export default {
  name: 'SettingModal',

  components: {
    SettingsMenu,
    SettingsEdit,
    SettingsTerm,
    SettingsHowto,
    SettingsAccount,
  },

  data() {
    return {
      page: 'menu'
    }
  },

  computed: {
    ...mapGetters({
      account: 'account'
    })
  },

  mounted() {
    const deviceId = localStorage.getItem('deviceid')
    if (deviceId == null) {
      this.page = 'term'
    }
  },

  methods: {
    onCloseWindow() {
      this.$emit('close-window')
    },

    onSwitchPage(page) {
      this.page = page
    },
  }

}
</script>

<style lang="scss">
.config-drawer{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      text-align: left;
      background: rgba(0, 0, 0, .6);
      #config-open2{
        display: inline-block;
        background: url(../../../public/images/config.png) center center no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover{
          background-image: url(../../../public/images/config_hover.png);
        }
      }
      .config-content{
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate3d(-50%, -50%, 0) rotate(-0.05deg);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 500px;
        max-height: 600px;
        width: 80%;
        height: 70%;
        margin: auto;
        background: url(../../../public/images/bg_config.png) center center no-repeat;
        background-size: 100% 100%;
        filter: drop-shadow(5px 6px 0px rgba(0, 0, 0, .2));
        z-index: 3;
        @media screen and (max-width: 599px){
          //left: 50%;
          width: 90%;
        }
        @media screen and (max-height: 500px){
          height: 85%;
        }
        .config_content_inner{
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
        .config_menu{
          height: 80%;
          display: grid;
          grid-template-columns: 45% 45%;
          grid-template-rows: 45% 45%;
          grid-gap: 2.5%;
          justify-content: center;
          align-content: center;
          .item1{
            grid-column: 1 / 2;
          }
          .item2{
            grid-column: 2 / 3;
          }
          .item3{
            grid-row: 1 / 2;
          }
          .item4{
            grid-row: 2 / 3;
          }
          li{
            position: relative;
            display: flex;
            align-items: center;
            font-size: clamp(13px, 2vw, 16px);
            &.color-burn::before{
              background: rgba(221, 163, 120, .9);
              border-radius: 15px;
            }
            &:hover::before{
              background: rgba(133, 91, 71, .9);
            }
            a{
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              height: 100%;
              color: #4c2e14;
              span{
                display: flex;
                justify-content: center;
                img{
                  max-width: 70px;
                  max-height: 70px;
                  height: 90%;
                }
                img.icon_account {
                  width: 90%;
                  height: auto;
                  max-width: 100px;
                  max-height: 100px;
                  transform: translateY(20%);
                }
              }
              span:first-child{
                align-items: flex-end;
                width: 90%;
                height: fit-content;
                margin: -.5rem auto;
                margin-bottom: clamp(5px, 1vmin, 10px);
              }
            }
          }
        }
        &.bigger{
          max-height: 800px;
          width: 100%;
          height: 85%;
          //top: 53%;
          background-image: url(../../../public/images/bg_howto.png);
        }
        &.middle {
          max-width: 500px;
          height: 300px;
          background-image: url(../../../public/images/bg_edit.png);
        }
        &.smaller {
          height: 200px;
          background-image: url(../../../public/images/bg_edit.png);
        }
      }
      .close{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 30px;
        margin: 10px auto 0;
        color: white;
        &.color-burn::before{
          top: -4px;
          left: -4px;
          border: 4px solid #6c2e25;
          border-radius: 10px;
          background: #764841;
          z-index: -1;
        }
      }
    }
    
</style>