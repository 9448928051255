import axios from 'axios'

const random = (n) => {
  let arr = Array.from(new Set(Array(n).fill().map(x => ~~(Math.random() * n))));

  let m = n - arr.length;

  if (m != 0) {
    for (let m; arr.length < n;) {
      arr = Array.from(new Set([...arr, ...Array(m).fill().map(x => ~~(Math.random() * n))]));
    }
  }
  return arr;
}

const state = {
  pageIndex: 0,
  contentsCycle: [
    { key: 'q001', type: 0 },   // エピソード選択
    { key: 'q002', type: 1 },   // レベル選択
    { key: 'q003', type: 2 },   // クイズ前キャラ会話
    { key: 'q004', type: 3 },   // クイズ画面
    { key: 'q005', type: 2 },   // クイズ後キャラ会話
    { key: 'q006', type: 4 },   // クイズ説明
    { key: 'q007', type: 2 },   // クイズ後キャラ会話
    { key: 'q008', type: 2 },   // クイズ終了（何問正解）
  ],
  selectedQuizLevel: 0,
  episode: 0,
  levels: null,
  episodeData: null,
  quizData: null,
  randomQuizzes: [],
  currentQuiz: 0,
  entry: {
    correctNum: 0
  }
}

const getters = {
  pageType(state) {
    return state.contentsCycle[state.pageIndex].type
  },
  pageCode(state) {
    return state.contentsCycle[state.pageIndex].key
  },

  pageIndex(state) {
    return state.pageIndex
  },

  quizLevels(state) {
    return state.levels != null ? state.levels : [];
  },

  selectLevel(state) {
    return state.levels.filter(_r => {
      return _r.id == state.selectedQuizLevel
    })[0]
  },

  selectEpisode(state) {
    return state.episodeData.filter(_r => {
      return _r.id == state.episode
    })[0]
  },

  episodeData(state) {
    return state.episodeData
  },

  currentQuiz(state) {
    return state.quizData ? state.quizData[state.currentQuiz] : null
  },

  currentQuizIndex(state) {
    return state.currentQuiz;
  },

  isEnded(state) {
    return state.currentQuiz + 1 == state.randomQuizzes.length
  },
  correctNum(state) {
    return state.entry.correctNum
  },
  numberOfQuiz(state) {
    return state.randomQuizzes.length
  }
}

const mutations = {
  pageIndex(state, payload) {
    state.pageIndex = payload
  },
  quizLevel(state, payload) {
    state.selectedQuizLevel = payload
  },
  levels(state, payload) {
    state.levels = payload
  },
  episode(state, payload) {
    state.episode = payload
  },
  episodeData(state, payload) {
    state.episodeData = JSON.parse(JSON.stringify(payload))
  },
  quizData(state, payload) {
    state.quizData = JSON.parse(JSON.stringify(payload))
  },
  randomQuizzes(state, payload) {
    state.randomQuizzes = payload
  },
  currentQuiz(state, payload) {
    state.currentQuiz = payload
  },
  correctAnswer(state, payload) {
    state.entry.correctNum = payload
  },
}

const actions = {
  nextPage(context) {
    console.log("nextPage", context.state.pageIndex + 1)
    const index = context.state.pageIndex + 1
    context.commit('pageIndex', index)
  },
  prevPage(context) {
    const index = context.state.pageIndex - 1
    context.commit('pageIndex', index)
  },
  movePage(context, payload) {
    console.log("movePage", payload)
    for (let i = 0; i < context.state.contentsCycle.length; i++) {
      if (context.state.contentsCycle[i].key == payload) {
        context.commit('pageIndex', i)
        break
      }
    }
  },
  nextQuiz(context) {
    context.commit('currentQuiz', context.state.currentQuiz + 1)
  },
  correctAnswer(context) {
    context.commit('correctAnswer', context.state.entry.correctNum + 1)
  },
  async loadQuizEpisode(context) {
    return await axios.get('/api/quiz/episode')
      .then(response => {
        context.commit('episodeData', response.data)
        return
      })
  },
  async loadQuizLevel(context, payload) {
    context.commit('episode', payload)
    return await axios.get(`/api/quiz/${payload}/level`)
      .then((response) => {
        context.commit('levels', response.data)
        return
      })
  },
  async loadQuizData(context, payload) {
    context.commit('quizLevel', payload)
    return await axios.get(`/api/quiz/level/${payload}`)
      .then(response => {
        context.commit('correctAnswer', 0)
        context.commit('currentQuiz', 0)
        
        context.commit('quizData', response.data)
        return
      })
  },
  randomQuizzes(context) {
    const rmds = random(context.state.quizData.length)    
    context.commit('randomQuizzes', rmds);
  },
  reset(context) {
    context.commit('currentQuiz', 0)
    context.commit('correctAnswer', 0)
    context.commit('pageIndex', 0)
  },
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
}
