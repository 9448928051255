<template>
  <div class="top">
    <div class="title">
      <div class="user" v-cloak>
        <p v-if="account != null" @click="clearLocalStorage">{{ account.nickname || account.name }} さん</p>
        <p v-else>プレイヤー名</p>
        <div class="point">
          <p v-cloak>獲得ポイント</p>
          <p>{{ point }}pt</p>
        </div>
      </div>
      <div class="header-menu">
        <a id="config-open" @click="onOpenSettingWindow"></a>
        <p class="bgm_icon" @click="onChangeSoundVolume"><img :src="soundMutedImage" alt="サウンド切り替えアイコン"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',

  data() {
    return {
      muted: 0,
      clickCount: 0,
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      point: 'point',
      isMute: 'isMute'
    }),
    
    soundMutedImage() {
      return !this.isMute ? '/images/bgm_on.png' : '/images/bgm_off.png';
    },
  },

  watch: {
    isMute(v) {
      const page = location.hash
      this.$nextTick(() => {
        this.soundControll(page)
      })
    },

    $route(to, from) {
      this.soundControll(to.path, from.path)
    }
  },

  methods: {
    clearLocalStorage() {
      if (this.clickCount >= 5) {
        this.$store.dispatch('resetLocalStorage')
        location.reload()
      } else {
        this.clickCount += 1
      }
    },

    onChangeSoundVolume() {
      this.$store.commit('isMute', !this.isMute)
      this.$sound.isMuted = this.isMute
      if (!this.isMute) {
        if (this.$router.currentRoute.value.path.indexOf('quiz') != -1) {
          this.$sound.play('quiz')
        } else {
          this.$sound.play('bgm')
        }
      } else {
        this.$sound.setVolume(0.0)
      }
     },

    soundControll(newpage, oldpage) {
      this.$sound.isMuted = this.isMute
      if (this.isMute) {
        this.$sound.setVolume(0.0)
      } else {
        console.log(oldpage, newpage)
        if (newpage.indexOf('quiz') != -1) {
          this.$sound.setVolume(0.0)
          this.$sound.play('quiz')
        } else if (typeof oldpage != 'undefined' && oldpage.indexOf('quiz') != -1) {
          this.$sound.setVolume(0.0)
          this.$sound.play('bgm')
        }
      }
    },

    onOpenSettingWindow() {
      this.$store.dispatch('openSettingsModal')
    },

    onCloseSettingWindow() {
      this.$store.dispatch('closeSettingModal')
    }
  },
/*
  method:{
    onChangeSoundVolume() {
      if (this.muted == 0) {
        this.sound.isMuted = false;
        this.muted = 2;
      } else if (this.muted == 2) {
        this.sound.isMuted = true;
        this.muted = 1;
      } else {
        this.sound.isMuted = false;
        this.muted = 2;
      }

      if (!this.sound.isMuted) {
        if (this.page.current == this.page.names[3]) {
          this.sound.play("quiz");
        } else {
          this.sound.play("bgm");
        }
      } else {
        this.sound.volume(0.0);
      }
    },
  }
*/
}

var isFocus = false;
const setFillHeight = () => {
  if (isFocus) return;
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight);
document.querySelectorAll('input[type=text]').forEach((elem) => {
  elem.addEventListener('onfocus', () => { isFocus = true; });
  elem.addEventListener('onblur', () => { isFocus = false; })
})
// 初期化
setFillHeight();

// ピンチイン・ピンチアウト禁止
document.documentElement.addEventListener('touchstart', function (e) {
  if (e.touches.length >= 2) {e.preventDefault();}
}, {
  passive: false
});
</script>

<style lang="scss">

  .user {
    width: 100%;
    color: white;
  }
  .point{
    display: flex;
    gap: 40px;
  }
  .header-menu {
    display:flex;
    gap: 0 10px;
    width: 100%;
    margin: 0 auto;
    text-align: right;
    @media screen and (max-width: 599px) {
      width: 25%;
    }
  }
  #config-open{
    display: none;
    background: url(../../public/images/config.png) center center no-repeat;
    background-size: contain;
    width: 26px;
    margin: 0 0 0 auto;
    cursor: pointer;
    display: block;
    &:hover{
      background-image: url(../../public/images/config_hover.png);
    }
  }
</style>
