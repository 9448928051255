<template>
  <div class="edit">
    <!--<h3>{{ pageTitle }}</h3>-->
    <div class="edit_inner">
      <h3 class="mb-10">ニックネーム入力</h3>
      <input type="text" id="nickname" name="nickname" v-model="nickname">
    </div>
    <div class="flex">
      <span><a href="javascript:void(0)" class="ok color-burn" @click="onSaveHandler"
          :class="{ unvalid: nickname == '' }">決定</a></span>
      <!--<label for="config-input" v-if="this.account !== null">
              <a href="javascript:void(0)" class="close color-burn" @click="onCloseHadnler">とじる</a>
          </label>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SettingsEdit',

  data() {
    return {
      nickname: '',
      pageTitle: '情報入力',
    }
  },

  computed: {
    ...mapGetters({
      account: 'account'
    }),    
  },

  mounted() {
    if (this.account != null) {
      this.nickname = this.account.name
      this.pageTitle = '名前変更'
    }
  },

  methods: {
    onCloseHadnler() {
      this.$emit('close-window', 'menu')
    },

    onSaveHandler() {
      if (this.nickname == '') {
        return false
      }

      const isInit = this.account == null;
      
      const _f = new FormData()
      _f.append('nickname', this.nickname)

      if (isInit) {
        this.$store.dispatch('createAccount', _f).then(() => {
          this.$store.dispatch('showAlrt', '保存しました。')
          this.$emit('close-window', 'menu')

          setTimeout(() => {
            this.$store.dispatch('showOverlay', 'howto')
          }, 1000)
        });
      } else {
        _f.append('_method', 'put')

        this.$store.dispatch('saveAccount', _f).then(() => {
          this.$store.dispatch('showAlrt', '保存しました。')
          this.$emit('close-window', 'menu')
        });
      }
    }
  }
}
</script>
