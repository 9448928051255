<template>
  <div class="bottom">
      <div class="dialogue" v-show="isConversations == false">
          <div class="shiorisan">
              <p class="img_box"><img src="images/shiorin.png" alt="しおりさん" width="100" height="170"></p>
              <div class="bubble" v-show="dialogueShioriText !== ''">
                  <div class="bubble_inner" v-html="dialogueShioriText">
                  </div>
              </div>
          </div>
          <div class="booksan" v-show="false">
              <p class="img_box"><img src="images/zukan.png" alt="本さん" width="100" height="100"></p>
              <div class="bubble" v-show="dialogueBookText != ''">
                  <div class="bubble_inner" v-html="dialogueBookText"></div>
              </div>
          </div>
      </div>
      <small>Copyright &copy; SPEED INC. All Right Reserved.</small>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      isConversations: true,
      dialogueShioriText: null,
      dialogueBookText: null,
    }
  },
}
</script>