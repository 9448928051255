<template>
    <!--スタート画面-->
    <div class="start_modal">
        <div class="start_box" @click="onCloseHandler">
            <p class="img_box img_box_start">
                <img src="images/starting.png" alt="スタート画面">
            </p>
            <div class="start_inner">
                <p class="tapToStart">タップでスタート</p>
            </div>
        </div>
        <p class="account_transfer_btn" @click="startTransfer">アカウント引き継ぎはこちら</p>
    </div>

    <!--アカウント引き継ぎモーダル-->
    <div class="modal account_modal" v-if="(modalType == 1)">
        <div class="account_transfer_container">
            <div class="account_transfer_box">
                <div>
                    <h3 class="mb-10">引き継ぎIDを入力</h3>
                    <div class="account_transfer_inner">
                        <input type="text" id="" name="" v-model="accountCode">
                        <p class="note">
                            ※引き継ぎたいデータの「ヘルプボタン」→「アカウント引き継ぎ」からIDを確認してください。
                        </p>
                    </div>
                </div>
            </div>
            
            <div class="btn_box">
                <span>
                    <a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">キャンセル</a>
                </span>
                <span>
                    <a href="javascript:void(0)" class="close color-burn" @click="checkAccount">決定</a>
                </span>
            </div>
        </div>
    </div>

    <!--アカウント引き継ぎモーダル 以下のアカウントを引き継ぎます-->
    <div class="modal account_modal" v-if="(modalType == 2)">
        <div class="account_transfer_container">
            <div class="account_transfer_box">
                <h3 class="mb-10">以下のアカウントを引き継ぎます。</h3>
                <div class="account_transfer_inner">
                    <p class="username">ユーザー名：{{ transferAccount.name }}さん</p>
                </div>
            </div>
            
            <div class="btn_box">
                <span>
                    <a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">キャンセル</a>
                </span>
                <span>
                    <a href="javascript:void(0)" class="close color-burn" @click="doTransfer">決定</a>
                </span>
            </div>
        </div>
    </div>

    <!--アカウント引き継ぎモーダル 引き継ぎIDがありません-->
    <div class="modal account_modal" v-if="(modalType == 9)">
        <div class="account_transfer_container">
            <div class="account_transfer_box">
                <h3>引き継ぎIDがありません</h3>
            </div>
            
            <div class="btn_box">
                <span>
                    <a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">もどる</a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  name: 'StartOverlay',

  data() {
    return {
        modalType: 0,
        accountCode: null,
        transferAccount: null,
    }
  },

  computed: {
    ...mapGetters({
        deviceId: 'deviceId',
        account: 'account',
    })
  },

  methods: {
    startTransfer(e) {
        e.preventDefault();
        this.modalType = 1;
    },

    onCloseHandler() {
        if (this.deviceId) {
            this.$store.dispatch('showOverlay', null)
        } else {
            this.$store.dispatch('showOverlay', 'opening')
        }
    },

    checkAccount() {
        this.$store.dispatch('transferAccount', this.accountCode)
            .then((uuid) => {
                if (uuid != null) {
                    this.$store.dispatch('loadTransferAccount', uuid)
                        .then((data) => {
                            this.modalType = 2
                            this.transferAccount = data
                        }).catch(err => {
                            this.modalType = 9
                        })
                } else {
                    this.$store.dispatch('showOverlay', 'opening')
                }
            }).catch(err => {
                this.modalType = 9
            })
    },

    doTransfer() {
        this.$store.dispatch('saveLocalStorage', {key: 'deviceid', value: this.transferAccount.id})
        this.$store.dispatch('completTranser', this.transferAccount.id)
        location.reload()
    },
  }
}
</script>

<style lang="scss" scoped>
    .start_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../../public/images/bg_start.jpg) center center no-repeat #955b36;
        background-size: cover;
        overflow: hidden;
        z-index: 5;
        .start_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 1080px;
            height: 90%;
            margin: 0 auto;
        }
        .img_box.img_box_start {
            max-height: 80%;
            margin: 0 auto;
            text-align: center;
            img {
                max-height: 100%;
            }
        }
        .tapToStart {
            font-size: clamp(26px, 5vw, 30px);
            font-weight: bold;
            color: #fff;
        }
    }
    .start_inner {
        margin: 20px 0 10px;
    }
    .account_transfer_btn {
        margin-top: 10px;
        padding: 3px 5px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        border: 3px solid #fff;
        border-radius: 10px;
        cursor: pointer;
    }
    .account_transfer_btn:active {
        opacity: .7;
    }

    .account_modal {
        display: flex;
        align-items: center;
        z-index: 6;
    }
    .account_transfer_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px 0;
        max-width: 400px;
        width: 90%;
        max-height: 300px;
        height: 100%;
        margin: 10px auto;
        background: url(../../../public/images/bg_edit.png) center center no-repeat #f0dec2;
        background-size: 100% 100%;
        word-break: break-all;
        .account_transfer_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 90%;
            height: 50%;
            margin: 0 auto;
        }
        h3{
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
        }
        .account_transfer_inner {
            max-width: 300px;
            margin: 0 auto;
            .note {
                margin: 20px 0 10px;
                padding-left: 1rem;
                text-indent: -1rem;
                @media screen and (max-width: 600px) {
                    padding-left: 0.875rem;
                    text-indent: -0.875rem;
                }
            }
        }
    }
    input[type="text"]{
        display: block;  
        width: 100%;
        margin: 0 auto;
        padding: 5px;
        background: rgba(255, 255, 255, .6);
        border: transparent;
        border-radius: 5px;
        font-size: 16px;
    }
    .btn_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 20px;
    }
    .username {
        margin: 20px 0;
        text-align: center;
        font-size: 16px;
    }
    .close{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 30px;
        margin: 10px auto 0;
        color: white;
        &.color-burn::before{
            top: -4px;
            left: -4px;
            border: 4px solid #6c2e25;
            border-radius: 10px;
            background: #764841;
            z-index: 0;
        }
    }
</style>