<template>
  <LoadingOverlay v-show="overlayCode == 'loading'" ref="loading" />
  <StartOverlay v-if="overlayCode == 'start'" />
  <OpeningOverlay v-if="overlayCode == 'opening'" />
  <HowtoOverlay v-if="overlayCode == 'howto'" />
  <NotificationOverlay v-if="overlayCode == 'notification'" />
  <div class="content_width">
    <AppHeader />
    <div class="contents">
      <ContentsLeft v-if="!isSmartPhone" />
      <div class="right">
        <router-view />
      </div>
    </div>

    <!-- 221228 v-if="isSmartPhone" 削除 -->
    <!-- <div class="config-drawer" v-if="isSmartPhone"> -->

    <!-- 221228 .config-drawerのみをSettingModal.vueに移植 -->
    <!-- <div class="config-drawer">
      <SettingModal v-if="isSettingModal" @close-window="onCloseSettingWindow" />
    </div> -->
    
    <SettingModal v-if="isSettingModal" @close-window="onCloseSettingWindow" />
    <MessageAlrt v-if="alert != null" />
    <PointDialog v-if="addedPoint > 0" />
    <Levelup v-if="overlayCode == 'levelup'" />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import ContentsLeft from '@/components/ContentsLeft.vue'
import HowtoOverlay from '@/components/overlay/Howto.vue'
import SettingModal from '@/components/settings/SettingModal.vue'
import Levelup from '@/components/overlay/Levelup.vue'
import MessageAlrt from '@/components/MessageAlrt.vue'
import StartOverlay from '@/components/overlay/Start.vue'
import OpeningOverlay from '@/components/overlay/Opening.vue'
import LoadingOverlay from '@/components/overlay/Loading.vue'
import PointDialog from '@/components/overlay/PointDialog.vue'
import NotificationOverlay from '@/components/overlay/Notification.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppFooter,
    ContentsLeft,
    HowtoOverlay,
    Levelup,
    SettingModal,
    MessageAlrt,
    StartOverlay,
    OpeningOverlay,
    LoadingOverlay,
    PointDialog,
    NotificationOverlay,
  },
  computed: {
    ...mapGetters({
      overlayCode: 'overlay',
      isSmartPhone: 'isSmartPhone',
      alert: 'alertMessageText',
      addedPoint: 'addedPoint',
      isSettingModal: 'isSettings',
      isApp: 'isApp'
    })
  },

  methods: {
    onOpenSettingWindow() {
      this.$store.dispatch('openSettingsModal')
    },

    onCloseSettingWindow() {
      this.$store.dispatch('closeSettingModal')
    },

    returnedHome(deviceId) {
      this.$store.dispatch('loadAccount', deviceId)
        .then(() => {
          this.$store.dispatch('setupApp', true)
          this.$store.commit('deviceId', deviceId)
          this.$store.dispatch('showOverlay', null)
          this.$store.dispatch('closeSettingModal')
        })
    },

    getQueryParam(key) {
      let url = new URL(location.href)
      return url.searchParams.has(key)
    }
  },

  mounted() {
    const deviceId = localStorage.getItem('deviceid')
    if (this.getQueryParam('app')) {
      this.returnedHome(deviceId)
    } else {
      this.$refs.loading.doLoading(5)
      .then(() => {
        if (deviceId) {
          this.$store.dispatch('loadAccount', deviceId)
            .then(() => {
              this.$store.commit('deviceId', deviceId)

              if (this.isApp) {
                this.$store.dispatch('showOverlay', 'start')                
              } else {
                this.$store.dispatch('showOverlay', null)
              }
            })
        } else {
          if (this.isApp) {
            this.$store.dispatch('showOverlay', 'start')
          } else {
            this.$store.dispatch('showOverlay', 'opening')
          }
          this.$store.dispatch('openSettingsModal')
        }
      })
    }
  },

}
</script>

