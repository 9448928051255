<template>
  <div class="alert">
      <p>{{ message }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MessageAlrt',

  computed: {
    ...mapGetters({
      message: 'alertMessageText'
    })
  }
}
</script>