<template>
  <div class="modal2">
    <div class="level_up" v-if="modalIndex == 0" @click="switchPage">
      <div class="bubble">
        <div class="bubble_inner">ホッホッホ。<br>レベルアップしましたぞ。</div>
      </div>
      <div class="level_up_img">
        <p class="spin_light"><img src="/images/light.png" alt="回る光"></p>
        <p class="zukan"><img :src="bookImage" alt="ニコニコの図鑑さん"></p>
        <div class="shine">
          <p><img src="/images/deco_shine/shine1.png" alt="装飾1"></p>
          <p><img src="/images/deco_shine/shine2.png" alt="装飾2"></p>
          <p><img src="/images/deco_shine/shine3.png" alt="装飾3"></p>
          <p><img src="/images/deco_shine/shine4.png" alt="装飾4"></p>
        </div>
      </div>
      <!--<p class="level_up_text"><img src="images/levelup.png" alt="レベルアップ"></p>-->
    </div>
    <div class="level_dialogue" v-if="modalIndex == 1">
      <div class="shiorisan">
        <p class="img_box"><img src="images/shiori2.png" alt="驚いているしおりさん"></p>
        <div class="bubble">
          <div class="bubble_inner">図鑑おじさんどうしたの！？<br>色が変わっちゃった！！</div>
        </div>
      </div>
      <div class="booksan">
        <p class="img_box"><img :src="bookImage" alt="ニコニコの図鑑さん"></p>
        <div class="bubble">
          <div class="bubble_inner">ホッホッホ。<br>レベルアップしましたぞ。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LevelupOverlay',

  data() {
    return {
      modalIndex: 0,
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
    }),
    bookImage() {
      return `/images/char/zukan/zukan_${this.getRankText()}_smile.png`;
    }
  },

  methods: {
    switchPage() {
      this.modalIndex += 1;
      setTimeout(() => {
        this.$store.dispatch('hiddenOverlay')
      }, 5000);
    },

    getRankText() {
      const ranks = ['normal', 'bronze', 'silver', 'gold', 'platinum'];
      if (this.account == null) return ranks[0];
      return ranks[this.account.particle.rank];
    }
  },
}
</script>

<style lang="scss" scoped>
.bubble {
  background: url(../../../public/images/book_bubble_v3.png) center center no-repeat;
  background-size: 100% 100%;
}
</style>