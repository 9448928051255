<template>
    <div class="modal loading_container">
        <p class="logo">
            <img src="images/v2_logo.png" alt="スマホで防災訓練ロゴ">
        </p>
        <div class="loading_box">
            <p class="loading_text">読み込み中…</p>
            <p class="loading_rate">{{ loadingCount }}%</p>
        </div>
        <p class="img_box">
            <img src="images/char/giu/giu_panic.png" alt="キャラクター">
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Loading',

    data() {
        return {
            counter: 0,
            stepCount: 1,
            isCancel: false,
        }
    },

    computed: {
        loadingCount() {
            return this.counter
        },
        ...mapGetters({
            overlayCode: 'overlay',
        })
    },

    mounted() {
    },

    methods: {
        async doLoading(upcount) {
            this.isCancel = false
            this.counter = 0
            this.stepCount = upcount
            return await this.countup()
        },

        async countup() {
            while (true) {
                if (this.isCancel) {
                    await this.sleep(1000)
                    break
                }
                this.counter += this.stepCount
                if (this.counter >= 100) {
                    await this.sleep(1000)
                    break
                }
                await this.sleep(100)
            }
        },

        async sleep(ms) {
            return await new Promise(resolve => setTimeout(resolve, ms))
        },

        loadCompleted() {
            this.counter = 100;
            this.isCancel = true
        }
    }
}
</script>

<style lang="scss" scoped>
.loading_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px 0;
    align-items: center;
    background: url(../../../public/images/bg_load.png) center center no-repeat;
    background-size: cover;
    background-color: #faeac1;
    .logo {
        margin: 0 auto;
    }
    .loading_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px 0;
        width: 300px;
        min-height: 150px;
        font-weight: bold;
        background: url(../../../public/images/bg_edit.png) center center no-repeat #f0dec2;
        background-size: 100% 100%;
        filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, .3));
        .loading_text {
            font-size: 1rem;
        }
        .loading_rate {
            font-size: 2rem;
        }
    }
    .img_box {
        max-width: 450px;
        width: 45vmax;
        animation: huwa 2s linear infinite;
        img {
            //max-height: 300px;
            transform: rotate(15deg);
        }
    }
}
@keyframes huwa{
    0% { transform: translateY(0) }
    33.33333% { transform: translateY(-10px) }
    66.66667% { transform: translateY(0) }
    80% { transform: translateY(5px) }
    100% { transform: translateY(0) }
}
</style>