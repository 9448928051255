<template>
  <div class="modal4">
      <div class="center">
          <div class="howto">
              <p></p>
              <a href="javascript:void(0)" class="close color-burn" @click="onCloseModal">とじる</a>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'HowtoOverlay',

    methods: {
        onCloseModal() {
          this.$store.dispatch('hiddenOverlay')
        }
    },
}
</script>