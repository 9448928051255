<template>
  <div class="config_content_inner">
    <ul class="config_menu">
        <li class="color-burn">
            <a href="javascript:void(0);" @click="onSelectPage('term')">
                <span><img src="images/icon_rule.svg" alt="利用規約"></span>
                <span>利用規約</span>
            </a>
        </li>
        <!--
        <li class="color-burn">
            <a href="javascript:void(0);" @click="onSelectPage('edit')">
                <span><img src="images/icon_edit.svg" alt="ニックネーム変更"></span>
                <span>ニックネーム変更</span>
            </a>
        </li>
        -->
        <li class="color-burn">
            <a href="javascript:void(0);" @click="onSelectPage('howto')">
                <span><img src="images/icon_help.svg" alt="遊び方ヘルプ"></span>
                <span>遊び方ヘルプ</span>
            </a>
        </li>
        <li class="color-burn">
            <a href="javascript:void(0);" @click="onSelectPage('accountTransfer')">
                <span><img src="images/icon_account.png" alt="アカウント引き継ぎ" class="icon_account"></span>
                <span>アカウント引き継ぎ</span>
            </a>
        </li>
    </ul>
    <!-- <label for="config-input2" class="bp_hide">
        <span class="close color-burn" @click="onCloseWindow">とじる</span>
    </label> -->
    <label for="config-input">
        <span class="close color-burn" @click="onCloseWindow">とじる</span>
    </label>
  </div><!--/div.config_content_inner-->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SettingsMenu',

  data() {
    return {
      linkUrl: 'https://www.ogakibousai.jp/'
    }
  },

  computed: {
    ...mapGetters({
      isApp: 'isApp',
    }),
    blankUrl() {
      return this.linkUrl;
    }
  },

  mounted() {
    if (this.isApp) {
      this.linkUrl = 'ogaki-webview://moveToHomePage';
    } else {
      this.linkUrl = 'https://www.ogakibousai.jp/';
    }
  },

  methods: {
    onCloseWindow() {
      this.$store.dispatch('closeSettingModal')
    },

    onSelectPage(target) {
      this.$emit('switch-page', target)
    },
  }
}
</script>
