<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  name: 'App',

  data() {
    return {
      deviceId: null,
      currentVersion: null,
    }
  },

  computed: {
    ...mapGetters({
      version: 'version'
    })
  },

  created() {
    document.body.addEventListener('click', () => {
      this.$sound.play('tap')
    });
  },

  mounted() {
    this.deviceId = localStorage.getItem('deviceid')
    this.currentVersion = localStorage.getItem('version');
  },

  methods: {
    setupApp() {
      this.$store.dispatch('setupApp', true)
    },

    update() {
      localStorage.setItem('version', this.version)
      location.reload()
    },

    checkUpdat() {
      if (this.currentVersion == null) return "1"
      if (this.version) {
        let _nver = Number(this.version.replace('.', ''))
        let _over = Number(this.currentVersion.replace('.', ''))
        if (_nver > _over) return  "1"
        return  "0"
      }
      return  "1"
    }
  }
}
</script>

<style>
</style>
