<template>
    <div class="rule">
        <h3>利用規約</h3>
        <div class="rule_inner rule_inner_web" v-if="!isApp">
            <div class="rule_inner_bg">
                <h4>スマホで防災訓練 利用規約</h4>
                <ol>
                    <li>
                        <p class="bold">第1条 本利用規約について</p>
                        <ol>
                            <li>1. アプリ利用規約（以下「本利用規約」といいます。）は、株式会社スピードが本Webサイト上で提供するサービスを、ユーザーが利用する際の一切の行為に適用されます。</li>
                            <li>2. 本利用規約は、本サービスの利用条件を定めるものです。ユーザーは、本利用規約に従い本サービスを利用するものとします。</li>
                            <li>3. ユーザーは、本サービスを使用した場合、本利用規約の全ての記載内容について同意したものとみなされます。</li>
                            <li>4. 運営は、運営の判断により、本利用規約をいつでも任意の理由で変更することができるものとします。</li>
                            <li>5. 変更後の利用規約は、運営が別途定める場合を除いて、本Webサイト上に表示した時点より効力を生じるものとします。</li>
                            <li>6. ユーザーが、変更後の本利用規約に同意できない場合は、直ちに本Webサービスの使用を停止するものとします。</li>
                            <li>7. ユーザーが、本利用規約の変更の効力が生じた後に本サービスをご利用になる場合には、変更後の利用規約の全ての記載内容に同意したものとみなされます。</li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第2条 利用条件等</p>
                        <ol>
                            <li>1. ユーザーは、自己の責任において本Webサイトにアクセスするものとします。なお、本サービスが全てのブラウザに対応することを保証するものではありません。</li>
                            <li>2. 利用者は、本Webサイトを利用するために必要となる端末及び推奨環境を、自己の費用と責任において準備し、本Webサイトが利用可能な状態に置くものとします。
                                また、自己の費用と責任で、任意の電気通信サービスを経由して本アプリに接続するものとします。</li>
                            <li>3. ユーザーは、本Webサイトにアクセスし、その利用を開始することが可能になった時点から本サービスを利用することができます。</li>
                            <li>4.
                                ユーザーは、アイテム等につき、本Webサイトで定められた範囲の利用権を有するのみであって、所有権、知的財産権等の権利を取得するものではなく、本Webサイト外のいかなる金品とも交換できるものではありません。
                            </li>
                            <li>5.
                                本Webサイトの著作権・その他の権利は、運営に帰属します。本規約は、明示的に定めがある場合を除き、ユーザーに本Webサイトの著作権その他いかなる権利も移転することを許諾するものではありません。
                            </li>
                            <li>
                                6. ユーザーは、次の行為を行ってはならないものとします。
                                <ol>
                                    <li>① 本サービスに影響を与える外部ツールの利用・作成・頒布・販売等を行う行為。</li>
                                    <li>② 運営の許諾を得ない売買行為、オークション行為、金銭支払やその他の類似行為。</li>
                                    <li>③ 本サービスのパラメーターデータを操作または変更しようとする行為。</li>
                                    <li>④ 運営が本来意図しない動作を利用して本サービス内での利益を得ようとする行為。（いわゆるチート行為）</li>
                                    <li>⑤ 本Webサイトを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本アプリのソースコード、構造、アイデア等を解析するような行為。</li>
                                    <li>⑥ 本Webサイトを複製、送信、譲渡、貸与、翻訳、翻案、改変、他のソフトウェアと結合等する行為。</li>
                                    <li>⑦ 本Webサイトに組み込まれているセキュリティデバイスまたはセキュリティコードを破壊するような行為。</li>
                                    <li>⑧ 本Webサイトの不具合や障害を不正な目的で利用する行為又はそれを第三者へ伝達する行為。</li>
                                    <li>⑨ その他、本Webサイトに関して本アプリ提供者が有する権利を侵害する行為。</li>
                                    <li>⑩ 第三者が上記各行為を行うことを助長する行為。</li>
                                    <li>⑪ 本Webサイト及び本規約に基づく本アプリの利用権を第三者に再許諾、譲渡、移転またはその他の方法で処分する行為。</li>
                                    <li>⑫ 本Webサイトに付されている著作権表示及びその他の権利表示を除去または変更する行為。</li>
                                    <li>⑬ サブリミナル効果を意図したコンテンツをアップロードする行為。</li>
                                    <li>⑭ 運営の許諾を得ない商品の広告、宣伝、その他スパムメール、チェーンメール等の勧誘を目的とする行為。</li>
                                    <li>⑮ 利用者情報の不正利用行為。</li>
                                    <li>⑯ 第三者に対して、自らのEメールアドレス・電話番号等を公開、通知する行為。</li>
                                    <li>⑰ 第三者の個人情報を同意なく収集、公開、または提供する行為。</li>
                                    <li>⑱ 他人の名義、会社等の組織名を名乗ること等による、なりすまし行為。</li>
                                    <li>⑲ その他、運営が不適切と判断する行為。</li>
                                </ol>
                            </li>
                            <li>7.
                                ユーザーが本規約のいずれかの条項に違反したときは、ユーザーに対して何時にても本規約に基づく本Webサイトの利用を終了させることができます。その場合、運営はユーザーに対して何らの責任を負うものではありません。
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第3条 免責</p>
                        <ol>
                            <li>1. 運営は、本サービスの利用により発生したユーザーの損害については、一切の賠償責任を負いません。</li>
                            <li>2. ユーザーが、本サービスを利用することにより、第三者に対し損害を与えた場合、 ユーザーは自己の費用と責任においてこれを賠償するものとします。</li>
                            <li>3. 運営は本サービスに発生した不具合、エラー、障害等により本サービスが利用できないことによって引き起こされた損害について一切の賠償責任を負いません。</li>
                            <li>4. 本サービスは、運営がその時点で提供可能なものとします。運営は提供する情報、コンテンツおよびソフトウェア等の情報についてその完全性、正確性、適用性、有用性、利用可能性、
                                安全性、確実性等につきいかなる保証も一切しません。また、本サービスがいかなる環境下でも利用可能であること、お客様のプレイデータが反映されることを保証するものでもありません。
                            </li>
                            <li>5. 運営はユーザーに対して、適宜情報提供やアドバイスを行うことがありますが、その結果について責任を負わないものとします。</li>
                            <li>6.
                                運営は、本アプリのバグその他を補修する義務および本アプリを改良または改善する義務は負いません。ただし、本Webサイトは更新を行う場合があります。この場合、かかる更新版またはバージョンアップ情報等も本アプリとして扱い、これらにも本規約が適用されます。
                            </li>
                            <li>7. 本サービスが何らかの外的要因によりデータ破損等をした場合、運営はその責任を負いません。</li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第4条 簡易位置情報（GPS）をご利用に際して</p>
                        <ol>
                            <li>1. 本Webサイトは、利用者の端末の位置情報を使用いたします。</li>
                            <li>2. 取得された利用者の位置情報は第三者へ開示、提供いたしません。</li>
                            <li>3. 利用される端末及び設定によっては位置情報を必要とする機能をご利用頂けない場合があります。</li>
                            <li>4.
                                GPS衛星や基地局から取得する位置情報の精度は、電波状況や周囲環境（測位場所、天候等）の影響をうけることがあります。このため、位置情報の精度について保証いたしません。その精度に起因する情報内容の誤差に関し運営は一切の責任を負いません。
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第5条 地図情報をご利用の際のご注意</p>
                        <ol>
                            <li>1. 天候による閉鎖、歩行者天国、新道開発、工事などで、実際に通行できない道を表示する場合があります。実際の交通規制にしたがってください。</li>
                            <li>2. 地図情報には収録されていない地域や場所がある旨ご留意ください。</li>
                            <li>3. 施設情報は名称等が変わっていたり、施設が廃止されている場合があります。</li>
                            <li>4. 本Webサイト内で表示しております自治体名（市区町村名）は、市区町村合併などにより実在するものと一致しない場合がございます。</li>
                            <li>5. 利用者は本Webサイトのご利用にあたり、歩行中、運転中に携帯電話を使用しないな
                                ど、道路交通法等の法令を遵守するものとします。携帯電話の操作中に事故等を起こした場合、運営は一切責任を負いません。</li>
                            <li>6. 表示された道路状況や交通規制が現況と異なるときは現況を優先してください。</li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第6条 個人情報の取扱い</p>
                        本Webサイトの利用に際して運営が取得する利用者の個人情報の取扱いについては、運営が定める「プライバシーポリシー」の内容に従うものとします。
                    </li>
                    <li>
                        <p class="bold">第7条 本Webサイトの変更および終了</p>
                        運営は、運営の都合により、本Webサイトの内容を変更し又は提供を終了することができます。
                        運営は、本Webサイトの内容を変更又は提供を終了したことに起因して利用者が損害を被ったとしても一切責任を負いません。
                    </li>
                    <li>
                        <p class="bold">第8条 法律の適用及び裁判管轄</p>
                        <ul>
                            <li>1. 本規約は、日本法に準拠し、日本法によって解釈されるものとします。</li>
                            <li>2. 本規約に関する紛争については、名古屋地方裁判所または瀬戸簡易裁判所を第一審の専属的合意管轄裁判所とします。</li>
                        </ul>
                    </li>
                </ol>
                <p class="mb-20">制定: 2022年1月30日</p>
                <h4 class="mb-10">スマホで防災訓練 プライバシーポリシー</h4>
                <dl>
                    <dt>□個人情報の定義</dt>
                    <dd>株式会社スピード（以下「運営」といいます。）における個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報(氏名、生年月日、その他の特定の個人を識別することができる情報)及び特定の個人と結びついて使用されるメールアドレスその他の個人に関する属性情報であるとします。
                    </dd>
                    <dt>□取得される情報の項目、利用目的</dt>
                    <dd>
                        運営は､取得した個人情報を以下の利用目的のために利用いたします｡
                        なお、情報をご提供いただけない場合、本Webサイトおよび本サービスの全部あるいは一部をご利用いただけないことがございます。
                        <dl>
                            <dt>【自動的に取得される情報】</dt>
                            <dd>
                                ・GPSによる端末の位置情報
                                　利用目的：アプリ内での現在位置の照合のため、アクセス解析及びデータ分析ため
                            </dd>
                            <dt>【お客様の入力により取得される情報】</dt>
                            <dd>
                                ・メールアドレス
                                　利用目的：運営が提供する本サービスおよび他の各種サービスに関するご案内のため、お問い合わせ対応のため
                            </dd>
                            <dd>
                                ・お問い合わせ内容
                                　利用目的：お問い合わせ対応のため、本Webサイトおよび本サービスの改善のため
                            </dd>
                            <dd>
                                ・携帯端末の機種名
                                　利用目的：お問い合わせ対応のため、本Webサイトおよび本サービスの改善のため
                            </dd>
                            <dd>
                                ・端末情報
                                　利用目的：お問い合わせ対応のため、本Webサイトおよび本サービスの改善のため
                            </dd>
                            <dd>
                                ・OSのバージョン
                                　利用目的：お問い合わせ対応のため、本Webサイトおよび本サービスの改善のため
                            </dd>
                        </dl>
                    </dd>
                    <dt>□通知・公表または同意取得の方法</dt>
                    <dd>
                        <ol>
                            <li>(1)
                                本Webサイトおよび本サービスは、本プライバシーポリシーをご確認いただき、内容をご理解したうえでご利用ください。本プライバシーポリシーは、本Webサイト初回アクセス時の表示画面、および本Webサイト内メニューページに掲示されており、この掲示をもって公表したものとします。
                            </li>
                            <li>(2)
                                本Webサイトおよび本サービスのご利用に際して、本Webサイト初回アクセス時に、利用規約とともに本プライバシーポリシーについて同意を取得いたします。なお、同意をいただけない場合には、本Webサイトをご利用いただけません。
                            </li>
                            <li>(3)本Webサイトによる情報送信を停止したい場合には、本Webサイトの利用を停止してください。</li>
                        </ol>
                    </dd>
                    <dt>□情報の提供</dt>
                    <dd>
                        【開発、運営のための情報提供】
                        提供する情報：利用者のアクションに関する情報・Webサイトの不具合の修正等に必要な情報
                        提供目的：開発対応の為
                        提供先
                        ・株式会社スピード
                        公式サイト：<a href="https://speedinc-jp.com" target="_blank"
                            rel="noopener noreferrer">https://speedinc-jp.com</a>
                    </dd>
                    <dt>□情報の開示、提供</dt>
                    <dd>
                        運営は、お客様の同意を得ることなく、本Webサイトおよび本サービスにてお客様より取得、保存した情報を、運営が利用者情報の取扱いを含む業務を委託している委託先を除き、本プライバシーポリシーに明示している第三者以外に開示または提供することはありません。ただし、以下の場合は除きます。
                        <ol>
                            <li>(1) 法令に基づく場合</li>
                            <li>(2) 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
                            <li>(3) 国の機関もしくは地方公共団体またはその委託を受けた者が、法令の定めにより遂行することに協力する必要がある場合であって、本人の同意を得ることによりその遂行に支障を及ぼすおそれがある場合</li>
                            <li>(4) その他、公益上、運営が必要と判断した場合であって、本人の同意を得ることが困難である場合</li>
                        </ol>
                    </dd>
                    <dt>□お問い合わせ窓口</dt>
                    <dd>
                        ・株式会社スピード
                        メール：info@speedinc-jp.com<br>
                        問い合わせ方法：電子メールでお問い合わせください。
                    </dd>
                    <dt>□変更をおこなう場合の手続き</dt>
                    <dd>
                        運営は、このプライバシーポリシーを随時更新することがあります。
                        修正された最新のプライバシーポリシーは常に本Webサイトにて開示されます。
                        このプライバシーポリシーの変更は、運営が本Webサイト上に掲載した時点から適用されるものとします。
                    </dd>
                </dl>
                <p>制定： 2022年1月30日</p>
            </div>
        </div>
        <div class="rule_inner rule_inner_app" v-if="isApp">
            <div class="rule_inner_bg">
                <h4>スマホで防災訓練 利用規約</h4>
                <ol>
                    <li>
                        <p class="bold">第1条 本利用規約について</p>
                        <ol>
                            <li>1. アプリ利用規約（以下「本利用規約」といいます。）は、株式会社スピードが本アプリ上で提供するサービスを、ユーザーが利用する際の一切の行為に適用されます。</li>
                            <li>2. 本利用規約は、本サービスの利用条件を定めるものです。ユーザーは、本利用規約に従い本サービスを利用するものとします。</li>
                            <li>3. ユーザーは、本サービスを使用した場合、本利用規約の全ての記載内容について同意したものとみなされます。</li>
                            <li>4. 運営は、運営の判断により、本利用規約をいつでも任意の理由で変更することができるものとします。</li>
                            <li>5. 変更後の利用規約は、運営が別途定める場合を除いて、本アプリ上に表示した時点より効力を生じるものとします。</li>
                            <li>6. ユーザーが、変更後の本利用規約に同意できない場合は、直ちに本アプリの使用を停止するものとします。</li>
                            <li>7. ユーザーが、本利用規約の変更の効力が生じた後に本サービスをご利用になる場合には、変更後の利用規約の全ての記載内容に同意したものとみなされます。</li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第2条 利用条件等</p>
                        <ol>
                            <li>1. ユーザーは、自己の責任において本アプリにアクセスするものとします。なお、本サービスが全ての端末に対応することを保証するものではありません。</li>
                            <li>2. 利用者は、本アプリを利用するために必要となる端末及び推奨環境を、自己の費用と責任において準備し、本アプリが利用可能な状態に置くものとします。
                                また、自己の費用と責任で、任意の電気通信サービスを経由して本アプリに接続するものとします。</li>
                            <li>3. ユーザーは、本アプリにアクセスし、その利用を開始することが可能になった時点から本サービスを利用することができます。</li>
                            <li>4.
                                ユーザーは、アイテム等につき、本アプリで定められた範囲の利用権を有するのみであって、所有権、知的財産権等の権利を取得するものではなく、本アプリ外のいかなる金品とも交換できるものではありません。
                            </li>
                            <li>5.
                                本アプリの著作権・その他の権利は、運営に帰属します。本規約は、明示的に定めがある場合を除き、ユーザーに本アプリの著作権その他いかなる権利も移転することを許諾するものではありません。
                            </li>
                            <li>
                                6. ユーザーは、次の行為を行ってはならないものとします。
                                <ol>
                                    <li>① 本サービスに影響を与える外部ツールの利用・作成・頒布・販売等を行う行為。</li>
                                    <li>② 運営の許諾を得ない売買行為、オークション行為、金銭支払やその他の類似行為。</li>
                                    <li>③ 本サービスのパラメーターデータを操作または変更しようとする行為。</li>
                                    <li>④ 運営が本来意図しない動作を利用して本サービス内での利益を得ようとする行為。（いわゆるチート行為）</li>
                                    <li>⑤ 本アプリを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本アプリのソースコード、構造、アイデア等を解析するような行為。</li>
                                    <li>⑥ 本アプリを複製、送信、譲渡、貸与、翻訳、翻案、改変、他のソフトウェアと結合等する行為。</li>
                                    <li>⑦ 本アプリに組み込まれているセキュリティデバイスまたはセキュリティコードを破壊するような行為。</li>
                                    <li>⑧ 本アプリの不具合や障害を不正な目的で利用する行為又はそれを第三者へ伝達する行為。</li>
                                    <li>⑨ その他、本アプリに関して本アプリ提供者が有する権利を侵害する行為。</li>
                                    <li>⑩ 第三者が上記各行為を行うことを助長する行為。</li>
                                    <li>⑪ 本アプリ及び本規約に基づく本アプリの利用権を第三者に再許諾、譲渡、移転またはその他の方法で処分する行為。</li>
                                    <li>⑫ 本アプリに付されている著作権表示及びその他の権利表示を除去または変更する行為。</li>
                                    <li>⑬ サブリミナル効果を意図したコンテンツをアップロードする行為。</li>
                                    <li>⑭ 運営の許諾を得ない商品の広告、宣伝、その他スパムメール、チェーンメール等の勧誘を目的とする行為。</li>
                                    <li>⑮ 利用者情報の不正利用行為。</li>
                                    <li>⑯ 第三者に対して、自らのEメールアドレス・電話番号等を公開、通知する行為。</li>
                                    <li>⑰ 第三者の個人情報を同意なく収集、公開、または提供する行為。</li>
                                    <li>⑱ 他人の名義、会社等の組織名を名乗ること等による、なりすまし行為。</li>
                                    <li>⑲ その他、運営が不適切と判断する行為。</li>
                                </ol>
                            </li>
                            <li>7.
                                ユーザーが本規約のいずれかの条項に違反したときは、ユーザーに対して何時にても本規約に基づく本アプリの利用を終了させることができます。その場合、運営はユーザーに対して何らの責任を負うものではありません。
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第3条 免責</p>
                        <ol>
                            <li>1. 運営は、本サービスの利用により発生したユーザーの損害については、一切の賠償責任を負いません。</li>
                            <li>2. ユーザーが、本サービスを利用することにより、第三者に対し損害を与えた場合、 ユーザーは自己の費用と責任においてこれを賠償するものとします。</li>
                            <li>3. 運営は本サービスに発生した不具合、エラー、障害等により本サービスが利用できないことによって引き起こされた損害について一切の賠償責任を負いません。</li>
                            <li>4. 本サービスは、運営がその時点で提供可能なものとします。運営は提供する情報、コンテンツおよびソフトウェア等の情報についてその完全性、正確性、適用性、有用性、利用可能性、
                                安全性、確実性等につきいかなる保証も一切しません。また、本サービスがいかなる環境下でも利用可能であること、お客様のプレイデータが反映されることを保証するものでもありません。
                            </li>
                            <li>5. 運営はユーザーに対して、適宜情報提供やアドバイスを行うことがありますが、その結果について責任を負わないものとします。</li>
                            <li>6.
                                運営は、本アプリのバグその他を補修する義務および本アプリを改良または改善する義務は負いません。ただし、本アプリは更新を行う場合があります。この場合、かかる更新版またはバージョンアップ情報等も本アプリとして扱い、これらにも本規約が適用されます。
                            </li>
                            <li>7. 本サービスが何らかの外的要因によりデータ破損等をした場合、運営はその責任を負いません。</li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第4条 簡易位置情報（GPS）をご利用に際して</p>
                        <ol>
                            <li>1. 本アプリは、利用者の端末の位置情報を使用いたします。</li>
                            <li>2. 取得された利用者の位置情報は第三者へ開示、提供いたしません。</li>
                            <li>3. 利用される端末及び設定によっては位置情報を必要とする機能をご利用頂けない場合があります。</li>
                            <li>4.
                                GPS衛星や基地局から取得する位置情報の精度は、電波状況や周囲環境（測位場所、天候等）の影響をうけることがあります。このため、位置情報の精度について保証いたしません。その精度に起因する情報内容の誤差に関し運営は一切の責任を負いません。
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第5条 地図情報をご利用の際のご注意</p>
                        <ol>
                            <li>1. 天候による閉鎖、歩行者天国、新道開発、工事などで、実際に通行できない道を表示する場合があります。実際の交通規制にしたがってください。</li>
                            <li>2. 地図情報には収録されていない地域や場所がある旨ご留意ください。</li>
                            <li>3. 施設情報は名称等が変わっていたり、施設が廃止されている場合があります。</li>
                            <li>4. 本アプリ内で表示しております自治体名（市区町村名）は、市区町村合併などにより実在するものと一致しない場合がございます。</li>
                            <li>5. 利用者は本アプリのご利用にあたり、歩行中、運転中に携帯電話を使用しないな
                                ど、道路交通法等の法令を遵守するものとします。携帯電話の操作中に事故等を起こした場合、運営は一切責任を負いません。</li>
                            <li>6. 表示された道路状況や交通規制が現況と異なるときは現況を優先してください。</li>
                        </ol>
                    </li>
                    <li>
                        <p class="bold">第6条 個人情報の取扱い</p>
                        本アプリの利用に際して運営が取得する利用者の個人情報の取扱いについては、運営が定める「プライバシーポリシー」の内容に従うものとします。
                    </li>
                    <li>
                        <p class="bold">第7条 本アプリの変更および終了</p>
                        運営は、運営の都合により、本アプリの内容を変更し又は提供を終了することができます。
                        運営は、本アプリの内容を変更又は提供を終了したことに起因して利用者が損害を被ったとしても一切責任を負いません。
                    </li>
                    <li>
                        <p class="bold">第8条 法律の適用及び裁判管轄</p>
                        <ul>
                            <li>1. 本規約は、日本法に準拠し、日本法によって解釈されるものとします。</li>
                            <li>2. 本規約に関する紛争については、名古屋地方裁判所または瀬戸簡易裁判所を第一審の専属的合意管轄裁判所とします。</li>
                        </ul>
                    </li>
                </ol>
                <p class="mb-20">制定： 2023年1月18日</p>


                <h4 class="mb-10">スマホで防災訓練 プライバシーポリシー</h4>
                
                <dl>
                    <dt>□個人情報の定義</dt>
                    <dd>株式会社スピード（以下「運営」といいます。）における個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報(氏名、生年月日、その他の特定の個人を識別することができる情報)及び特定の個人と結びついて使用されるメールアドレスその他の個人に関する属性情報であるとします。
                    </dd>
                    <dt>□取得される情報の項目、利用目的</dt>
                    <dd>
                        運営は､取得した個人情報を以下の利用目的のために利用いたします｡
                        なお、情報をご提供いただけない場合、本アプリおよび本サービスの全部あるいは一部をご利用いただけないことがございます。
                        <dl>
                            <dt>【自動的に取得される情報】</dt>
                            <dd>
                                ・GPSによる端末の位置情報
                                　利用目的：アプリ内での現在位置の照合のため、アクセス解析及びデータ分析ため
                            </dd>
                            <dt>【お客様の入力により取得される情報】</dt>
                            <dd>
                                ・メールアドレス
                                　利用目的：運営が提供する本サービスおよび他の各種サービスに関するご案内のため、お問い合わせ対応のため
                            </dd>
                            <dd>
                                ・お問い合わせ内容
                                　利用目的：お問い合わせ対応のため、本アプリおよび本サービスの改善のため
                            </dd>
                            <dd>
                                ・携帯端末の機種名
                                　利用目的：お問い合わせ対応のため、本アプリおよび本サービスの改善のため
                            </dd>
                            <dd>
                                ・端末情報
                                　利用目的：お問い合わせ対応のため、本アプリおよび本サービスの改善のため
                            </dd>
                            <dd>
                                ・OSのバージョン
                                　利用目的：お問い合わせ対応のため、本アプリおよび本サービスの改善のため
                            </dd>
                        </dl>
                    </dd>
                    <dt>□通知・公表または同意取得の方法</dt>
                    <dd>
                        <ol>
                            <li>(1)
                                本アプリおよび本サービスは、本プライバシーポリシーをご確認いただき、内容をご理解したうえでご利用ください。本プライバシーポリシーは、本アプリ初回アクセス時の表示画面、および本アプリ内メニューページに掲示されており、この掲示をもって公表したものとします。
                            </li>
                            <li>(2)
                                本アプリおよび本サービスのご利用に際して、本アプリ初回アクセス時に、利用規約とともに本プライバシーポリシーについて同意を取得いたします。なお、同意をいただけない場合には、本アプリをご利用いただけません。
                            </li>
                            <li>(3)本アプリによる情報送信を停止したい場合には、本アプリの利用を停止してください。</li>
                        </ol>
                    </dd>
                    <dt>□情報の提供</dt>
                    <dd>
                        【開発、運営のための情報提供】
                        提供する情報：利用者のアクションに関する情報・Webサイトの不具合の修正等に必要な情報
                        提供目的：開発対応の為
                        提供先
                        ・株式会社スピード
                        公式サイト：<a href="https://speedinc-jp.com" target="_blank"
                            rel="noopener noreferrer">https://speedinc-jp.com</a>
                    </dd>
                    <dt>□情報の開示、提供</dt>
                    <dd>
                        運営は、お客様の同意を得ることなく、本アプリおよび本サービスにてお客様より取得、保存した情報を、運営が利用者情報の取扱いを含む業務を委託している委託先を除き、本プライバシーポリシーに明示している第三者以外に開示または提供することはありません。ただし、以下の場合は除きます。
                        <ol>
                            <li>(1) 法令に基づく場合</li>
                            <li>(2) 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
                            <li>(3) 国の機関もしくは地方公共団体またはその委託を受けた者が、法令の定めにより遂行することに協力する必要がある場合であって、本人の同意を得ることによりその遂行に支障を及ぼすおそれがある場合</li>
                            <li>(4) その他、公益上、運営が必要と判断した場合であって、本人の同意を得ることが困難である場合</li>
                        </ol>
                    </dd>
                    <dt>□お問い合わせ窓口</dt>
                    <dd>
                        ・株式会社スピード
                        メール：info@speedinc-jp.com<br>
                        問い合わせ方法：電子メールでお問い合わせください。
                    </dd>
                    <dt>□変更をおこなう場合の手続き</dt>
                    <dd>
                        運営は、このプライバシーポリシーを随時更新することがあります。
                        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
                        このプライバシーポリシーの変更は、運営が本サービス公式Webページ、アプリ上に掲載した時点から適用されるものとします。
                    </dd>
                </dl>
                <p>制定： 2023年1月18日</p>
            </div>
        </div>
        <label class="accept">
            <input type="checkbox" name="term" value="ok" v-model="checkAgree">利用規約に同意する。
        </label>
        <label for="config-input">
            <a href="javascript:void(0)" class="close color-burn" @click="onCloseClickHandler" v-if="isAgreed"
                :class="{unvalid: !checkAgree}">とじる</a>
            <a href="javascript:void(0)" class="close color-burn" @click="onAgreeClickHandler" v-if="!isAgreed"
                :class="{unvalid: !checkAgree}">進む</a>
        </label>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SettingsTerm',

  data() {
      return {
          classObjects: [],
          isAgreed: false,
          checkAgree: false
      }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      isApp: 'isApp',
    })
  },

  mounted() {
    const _deviceid = localStorage.getItem('deviceid')
    if (_deviceid) {
      this.isAgreed = true
      this.checkAgree = true
    } 
  },

  methods: {
      onAgreeClickHandler() {
        if (!this.checkAgree) return
        this.$emit('check-agree', 'edit')
      },

      onCloseClickHandler() {
        if (!this.checkAgree) return
        this.$emit('check-agree', 'menu')
      },
  }
}
</script>

<style lang="scss" scoped>
    ol {
        margin-top: 10px;
    }
    li, dd {
        margin-bottom: 10px;
    }
    .bold {
        margin: 20px 0 10px;
        font-weight: bold;
    }
</style>