<template>
  <div class="point_get is-animated">
    <p v-cloak> {{ addedPoint }}ポイント<br>ゲット！</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PointDailog',

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      addedPoint: 'addedPoint'
    })
  }
}
</script>
