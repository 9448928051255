import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: DefaultLayout,
    redirect: '/home',

    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/pages/home'),
        meta: { title: '｜やることリスト'},
      },
      {
        path: '/goods',
        name: 'Goods',
        component: () => import('@/pages/goods'),
        meta: { title: '｜防災グッズ登録'},
      },
      {
        path: '/quiz',
        name: 'Quiz',
        component: () => import('@/pages/quiz'),
        meta: { title: '｜防災クイズ'},
      },
      {
        path: '/info',
        name: 'Info',
        component: () => import('@/pages/info'),
        meta: { title: '｜防災情報を確認'},
      },

      //クイズhtml確認用URL
      {
        path: '/quizimage',
        name: 'Quizimage',
        component: () => import('@/components/quiz/ContentsViewImage'),
        meta: { title: '｜画像クイズhtml確認用URL'},
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

const DEFAULT_TITLE = 'スマホで防災訓練'
router.afterEach((to,from) => {
  document.title = DEFAULT_TITLE + to.meta.title || DEFAULT_TITLE
})

export default router