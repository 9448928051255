<template>
  <div class="help" v-cloak>
      <h3>遊び方へルプ</h3>
      <div class="help_inner">
          <div class="help_text">
              <h4>防災グッズ登録</h4>
              <dl>
                  <div>
                      <dt><img src="/images/help/ex1.png" alt="防災グッズ登録画面のアイテム"></dt>
                      <dd>
                          自宅に用意している防災グッズを探そう！
                      </dd>
                  </div>
                  <div>
                      <dt><img src="/images/help/ex2.png" alt="防災グッズ登録画面のアイテムにチェックを入れる"></dt>
                      <dd>
                          右上の□を選択してチェック！
                      </dd>
                  </div>
                  <div>
                      <dt><img src="/images/help/ex3.png" alt="保存ボタン"></dt>
                      <dd>
                          最後に保存ボタンで記録しよう。<br>登録数に応じてポイントゲット！
                      </dd>
                  </div>
              </dl>
          </div>
          <div class="help_text">
              <h4>防災クイズ</h4>
              <dl>
                  <div>
                      <dt><img src="/images/help/quiz_ex1.png" alt="クイズ問題文"></dt>
                      <dd>
                          問題文を読もう！
                      </dd>
                  </div>
                  <div>
                      <dt><img src="/images/help/quiz_ex2.png" alt="クイズ選択肢"></dt>
                      <dd>
                          正解だと思う答えをタップで選択しよう。
                      </dd>
                  </div>
                  <div>
                      <dt><img src="/images/help/quiz_ex3.png" alt="答えるボタン"></dt>
                      <dd>
                          最後に答えるボタンで回答しよう。<br>全問正解でポイントゲット！
                      </dd>
                  </div>
              </dl>
          </div>
          <!-- <div class="help_text">
              <h4>防災情報を確認</h4>
          </div> -->
      </div>
      <label for="config-input">
          <a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">とじる</a>
      </label>
  </div>
</template>

<script>
export default {
  name: 'SettingsHowto',

  methods: {
    onCloseHandler() {
      this.$emit('switch-page', 'menu')
    }
  }
}
</script>
