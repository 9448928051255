<template>
  <div class="left bp_hide">
      <div class="user color-burn" v-cloak>
          <p v-if="account != null">{{ account.nickname || account.name }} さん</p>
          <p v-cloak>獲得ポイント {{ point }}pt</p>
      </div>
      <div class="config-drawer" v-if="!isSmartPhone">
          <a id="config-open2" @click="onOpenSettingWindow"></a>
          <SettingModal v-if="isSettingModal || account == null" @close-window="onCloseSettingWindow" />
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingModal from '@/components/settings/SettingModal'

export default {
  name: 'ContentsLeft',

  components: {
    SettingModal
  },

  data() {
      return {
      }
  },

  computed: {
      ...mapGetters({
          account: 'account',
          point: 'point',
          isSmartPhone: 'isSmartPhone',
          isSettingModal: 'isSettings'
      }),
  },

  methods: {
      onOpenSettingWindow() {
        this.$store.dispatch('openSettingsModal')
      },

      onCloseSettingWindow() {
        this.$store.dispatch('closeSettingModal')
      }
  }
}
</script>
