<template>
  <div class="modal">
    <div class="popup_container">
      <div class="popup_box">
        
        <!-- AR開放時表示 -->
        <div id="ar_unlocked" v-if="unlocked.ar == 1">
          <p class="img_box"><img src="/images/clear1.png" alt="防災クイズ水害編クリア！"></p>
          <p class="text_box">
            <span class="title">浸水体験AR</span>が<br>
            遊べるようになりました
          </p>
          <!-- <a href="javascript:void(0)" class="close color-burn">とじる</a> -->
        </div>

        <!-- 脱出ゲーム開放時表示 -->
        <div id="game_unlocked" v-if="unlocked.game == 1">
          <p class="img_box"><img src="/images/clear2.png" alt="防災クイズ地震編クリア！"></p>
          <p class="text_box">
            <span class="title">脱出ゲーム</span>が<br>
            遊べるようになりました
          </p>
          <!-- <a href="javascript:void(0)" class="close color-burn">とじる</a> -->
        </div>

        <!-- 装飾キラキラ -->
        <p class="deco_box shine1"><img src="/images/deco_shine/shine5.png" alt="装飾1"></p>
        <p class="deco_box shine2"><img src="/images/deco_shine/shine6.png" alt="装飾2"></p>
        <p class="deco_box shine3"><img src="images/deco_shine/shine7.png" alt="装飾3"></p>
        <p class="deco_box shine4"><img src="images/deco_shine/shine8.png" alt="装飾4"></p>
        
        <!-- 装飾キャラクター -->
        <p class="char_box giu"><img src="/images/char/giu/giu_panic.png" alt="ギウちゃん"></p>
        <p class="char_box shiori"><img src="/images/char/shiori/shiori_normal2.png" alt="しおりくん"></p>
        <p class="char_box zukan"><img src="/images/char/zukan/zukan_normal_smile.png" alt="図鑑おじさん"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NotificationOverlay',

  data() {
    return {
      dialogType: "",
    }
  },

  computed: {
    ...mapGetters({
      unlocked: 'unlocked'
    })
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.popup_container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;
  margin: 0 auto;
}
.popup_box{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  width: clamp(250px, 50vmax, 350px);
  margin-top: -5%;
  height: auto;
  aspect-ratio: 16 / 9;
  background: url(../../../public/images/bg_account.png) center center no-repeat;
  background-size: 100% 100%;
  filter: drop-shadow(5px 5px 0 rgba(0, 0, 0, 0.2));
  .img_box{
    width: 80%;
    margin: 0 auto 0;
  }
  .text_box{
    margin-top: 10px;
    text-align: center;
    font-size: clamp(14px, 2.8vw, 20px);
    font-weight: bold;
    line-height: 1.35;
    .title{
      font-size: clamp(22px, 4.8vw, 36px);
    }
  }
  .deco_box{
    position: absolute;
    &.shine1{
      max-width: 71px;
      width: 20.29%;
      top: -30%;
      left: 5.43%;
      animation: 2s ease-out infinite fade;
    }
    &.shine2{
      max-width: 66px;
      width: 18.86%;
      bottom: 15.94%;
      left: -11.7%;
      animation: 1.5s ease-out infinite fade;
    }
    &.shine3{
      max-width: 63px;
      width: 18%;
      top: -28.9%;
      right: 7.71%;
      animation: 2s ease-out infinite fade2;
    }
    &.shine4 {
      max-width: 111px;
      width: 31.71%;
      bottom: -23.67%;
      right: -15.71%;
      animation: 1.5s ease-out infinite fade2;
    }
  }
  .char_box {
    position: absolute;
    &.giu {
      max-width: 143px;
      width: 40%;
      top: -45%;
      left: 50%;
      transform: rotate(14deg) translateX(-50%)
    }
    &.shiori {
      max-width: 85px;
      width: 24.29%;
      bottom: -40%;
      left: 0;
      transform: rotate(-15deg);
    }
    &.zukan {
      max-width: 209px;
      width: 59.71%;
      bottom: -80%;
      right: -13%;
      transform: rotate(10deg);
    }
  }
}
</style>
