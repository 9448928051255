import { createApp } from 'vue'
import router from './router/'
import store from './store/'
import App from './App.vue'
import Sound from './plugins/Sound'

import "@/assets/scss/app.scss"

import { createGtm } from '@gtm-support/vue-gtm';

const sound = new Sound([
  { key: "bgm", file: '/assets/audios/bgm.mp3', loop: true },
  { key: "correct", file: '/assets/audios/correct.mp3', loop: false },
  { key: "incorrect", file: '/assets/audios/incorrect.mp3', loop: false },
  { key: "quiz", file: '/assets/audios/quiz.mp3', loop: true },
  { key: "tap", file: '/assets/audios/tap.mp3', loop: false },
  { key: "levelup", file: '/assets/audios/levelup.mp3', loop: false },
  { key: "addpoint", file: '/assets/audios/addpoint.mp3', loop: false },
]);


/*Vue.use(VueGtm, {
  id: 'GTM-M9767ZL',
});*/

//const app = createApp(App);

const app = createApp(App)
  .use(router)
  .use(store)
  .use(sound)
  .use(
    createGtm({
      id: 'GTM-M9767ZL',
    }),
  )

window.unityApp = app.mount('#app')
