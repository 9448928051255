/**
 * Audio Player
 */
import BufferLoader from "./BufferLoader";

export default class Sound {

  constructor(dataSource) { 
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    this.context = new AudioContext();
    this.isMuted = true;
    this.defaultVolume = 0.4;
    this.audios = [];

    var loader = new BufferLoader(
        this.context,
        dataSource,
        (list) => {
            this.audios = list;
            this.isReady = true;
        }
    );

    loader.load();
  }

  install(app) {
    app.config.globalProperties.$sound = this;
  }

  play(key, volume) {
    let audio = this.getSource(key);

    if (audio == null) {
        console.error("not found audio file!!");
        return;
    }

    if (audio.status != 0) {
        audio.source.stop();
        audio.source = null;
    }

    audio.source = this.context.createBufferSource();
    audio.source.loop = audio.loop;
    audio.source.buffer = audio.buffer;
    audio.gainNode = this.context.createGain();
    audio.source.connect(audio.gainNode);
    audio.gainNode.connect(this.context.destination);

    if (this.isMuted) audio.gainNode.gain.value = 0.0;
    else audio.gainNode.gain.value = volume || this.defaultVolume;

    audio.status = 1;
    audio.source.start(0);
  }

  setVolume(volume) {
    this.audios.forEach((audio) => {
        if (audio.status == 1) {
            audio.gainNode.gain.value = volume;
        }
    });
  }

  getSource(key) {
    let audios = this.audios.filter((audio) => { return audio.key == key });
    return audios.length > 0 ? audios[0] : null;
  }
}