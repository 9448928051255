<template>
  <!-- 各画面をdisplay:none;にしているので、表示時はdisplay:flex;にしてください -->

  <!-- 選択画面 -->
  <div id="account_choose" class="setting_account" v-if="popupId == 'top'" :class="{fix_container: popupId == 'top'}">
    <div class="setting_account_box">
      <h3 class="mb-10">アカウント引き継ぎ</h3>
      <ul>
        <li><a href="javascript:void(0)" class="color-burn btn" @click="onMoveTo('input')">引き継ぎIDを入力</a></li>
        <li><a href="javascript:void(0)" class="color-burn btn" @click="onMoveTo('transfer')">引き継ぎIDの発行</a></li>
      </ul>
    </div>
    <div>
      <span><a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">もどる</a></span>
    </div>
  </div>

  <!--入力画面-->
  <div id="account_input" class="account_transfer_container setting_account" v-if="popupId == 'input'" :class="{fix_container: popupId == 'input'}">
    <div class="account_transfer_box setting_account_box">
      <div>
        <h3 class="mb-10">引き継ぎIDを入力</h3>
        <div class="account_transfer_inner">
          <input type="text" id="" name="" v-model="accountCode">
          <p class="note">
            ※引き継ぎたいデータの「ヘルプボタン」→「アカウント引き継ぎ」からIDを確認してください。
          </p>
        </div>
      </div>
    </div>
    
    <div class="btn_box">
      <span><a href="javascript:void(0)" class="close color-burn" @click="onMoveTo('top')">もどる</a></span>
      <span><a href="javascript:void(0)" class="close color-burn" @click="checkAccount">決定</a></span>
    </div>
  </div>

  <!--入力画面 以下のアカウントを引き継ぎます-->
  <div id="account_success" class="account_transfer_container setting_account" v-if="popupId == 'confirm'" :class="{fix_container: popupId == 'confirm'}">
    <div class="account_transfer_box setting_account_box">
      <h3 class="mb-10">以下のアカウントを引き継ぎます。</h3>
      <div class="account_transfer_inner">
        <p class="username">ユーザー名：{{ transferAccount.name }}さん</p>
      </div>
    </div>
    
    <div class="btn_box">
      <span><a href="javascript:void(0)" class="close color-burn" @click="onMoveTo('input')">もどる</a></span>
      <span><a href="javascript:void(0)" class="close color-burn" @click="doTransfer">決定</a></span>
    </div>
  </div>

  <!--入力画面 引き継ぎIDがありません-->
  <div id="account_failure" class="account_transfer_container setting_account" v-if="popupId == 'error'" :class="{fix_container: popupId == 'error'}">
    <div class="account_transfer_box setting_account_box">
      <h3>引き継ぎIDがありません</h3>
    </div>
    
    <div class="btn_box">
      <span>
        <a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">とじる</a>
      </span>
    </div>
  </div>

  <!-- 引き継ぎID表示画面 -->
  <div id="account_show" class="setting_account" v-if="popupId == 'transfer'" :class="{fix_container: popupId == 'transfer'}">
    <div class="setting_account_box">
      <h3 class="mb-10">引き継ぎIDの発行</h3>
      <div class="setting_account_inner">
        <p class="account_id">{{ transferCode }}</p>
        <p>※引き継ぎ先のID入力画面で上のIDを入力してください。</p>
      </div>
    </div>
    <div>
      <span><a href="javascript:void(0)" class="close color-burn" @click="onCloseHandler">とじる</a></span>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
  name: 'SettingsAccount',

  data() {
    return {
      transferCode: "",
      deviceId: "",
      popupId: "top",
      transferAccount: null,
      accountCode: null,
    }
  },

  mounted() {
    this.deviceId = localStorage.getItem('deviceid');
  },

  methods: {
    onCloseHandler() {
      this.$emit('switch-page', 'menu')
    },

    async getTransferCode(id) {
      await axios.get('/api/device/invitation/' + id)
        .then(res => {
          this.transferCode = res.data.code;
        })
    },

    onMoveTo(popup) {
      this.popupId = popup
      if (popup == 'transfer') {
        this.getTransferCode(this.deviceId)
      }
    },

    doTransfer() {
        this.$store.dispatch('saveLocalStorage', {key: 'deviceid', value: this.transferAccount.id})
        this.$store.dispatch('completTranser', this.transferAccount.id)
        location.reload()
    },

    checkAccount() {
        this.$store.dispatch('transferAccount', this.accountCode)
            .then((uuid) => {
                if (uuid != null) {
                    this.$store.dispatch('loadTransferAccount', uuid)
                        .then((data) => {
                          this.popupId = 'confirm'
                          this.transferAccount = data
                        }).catch(err => {
                          this.popupId = 'error'
                        })
                } else {
                    //this.$store.dispatch('showOverlay', 'opening')
                    this.popupId = 'error'
                }
            }).catch(err => {
              this.popupId = 'error'
            })
    },
  }
}
</script>

<style lang="scss" scoped>
#account_choose{
  // display: none;
  ul li {    
    max-width: 350px;
    width: 90%;
    margin: 0 auto;
    font-size: 1rem;
    a.btn {
      display: inline-block;
      width: calc(100% - 20px);
      margin-top: 10px;
      padding: 10px 10px;
      text-align: center;
    }
    a.color-burn::before {
      background: rgba(221, 163, 120, 1);
      border-radius: 5px;
    }
  }
}
#account_input{
  /*display: none;*/
}
#account_success{
  /*display: none;*/
}
#account_failure{
  /*display: none;*/
  .setting_account_box {
    justify-content: center;
  }
}
#account_show{
  /*display: none;*/
}
.fix_container {
  display: flex;  
}

.account_transfer_container{
  display: flex;
}
.account_transfer_inner{
  max-width: 350px;
  width: 95%;
  margin: 0 auto;
  .note{
    margin: 15px 0 0;
    font-size: 0.875rem;
    padding-left: 0.875rem;
    text-indent: -0.875rem;
    text-align: left;
  }
}
input[type="text"]{
  display: block;  
  width: calc(100% - 10px);
  margin: 0 auto;
  padding: 5px;
  background: rgba(255, 255, 255, .6);
  border: transparent;
  border-radius: 5px;
  font-size: 16px;
}
.btn_box{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
}
.username{
  margin: 20px 0;
  text-align: center;
  font-size: 16px;
}
</style>