<template>
  <div class="modal3">
      <div class="op" @click="onClickPagenation" v-show="page == 0">
          <p class="logo">
              <img src="images/v2_logo.png" alt="スマホで防災訓練ロゴ">
              <!-- 221228アプリ化の際に不要 -->
              <!-- <span class="tapToNext sp_hide">タップですすむ</span> -->
              <span class="tapToNext bp_hide">タップですすむ</span>
          </p>
          <div class="op_dialogue">
              <div class="charas">
                  <div class="shiorisan">
                      <p class="img_box"><img src="images/char/shiori/shiori_smile.png" alt="笑っているしおりさん"></p>
                      <div class="bubble">
                          <div class="bubble_inner" v-html="dialogue.bookmark"></div>
                      </div>
                  </div>
                  <div class="booksan">
                      <p class="img_box"><img src="images/char/zukan/zukan_normal_smile.png" alt="ニコニコの図鑑さん"></p>
                      <div class="bubble">
                          <div class="bubble_inner" v-html="dialogue.book"></div>
                      </div>
                  </div>
              </div>
          </div>
          <p class="tapToNext pc_hide">タップですすむ</p>
      </div>
      <div class="op about" v-show="page == 1">
          <p class="logo">
              <img src="images/v2_logo.png" alt="スマホで防災訓練ロゴ">
          </p>
          <div class="op_dialogue about_container">
              <div class="about_box">
                  <div class="about_box_inner">
                    「スマホで防災訓練」はスマホでできる防災訓練サービスです。<br>
                    防災力の向上のため、ご利用ください。
                  </div>
              </div>
              <div class="charas">
                  <div class="shiorisan">
                      <p class="img_box"><img src="images/char/shiori/shiori_smile.png" alt="笑っているしおりさん"></p>
                  </div>
                  <div class="booksan">
                      <p class="img_box"><img src="images/char/zukan/zukan_normal_smile.png" alt="ニコニコの図鑑さん"></p>
                  </div>
              </div>
          </div>
          <p class="tapToStart" @click="onStartApp"><img src="images/startbtn.png" alt="スタートボタン"></p>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'OpeningOverlay',

  data() {
    return {
      dialogue: {
        book: '',
        bookmark: '',
      },
      dialogues: [],
      page: 0,
      textIndex: 0,
    }
  },

  computed: {
    ...mapGetters({
        isApp: 'isApp',
    })
  },

  created() {
    let api = this.isApp ? '/api/dialogues/op_app' : '/api/dialogues/op_web'
    axios.get(api)
        .then(responses => {
            this.dialogues = responses.data
            this.setDialogueText()
        })
  },

  methods: {
    setDialogueText() {
        this.dialogue.book = ""
        this.dialogue.bookmark = ""

        const text = this.dialogues[this.textIndex]
        this.dialogue[text.charcode] = text.serif
    },

    onClickPagenation() {
        if (this.dialogues.length == 0) return

        if (this.textIndex + 1 == this.dialogues.length) {
            this.page += 1
        } else {
            this.textIndex += 1
            this.setDialogueText()
        }
    },

    onStartApp() {
        this.$store.dispatch('hiddenOverlay')
    }
  }
}
</script>