import axios from 'axios'

const state = {
  pageIndex: 0,
  contentsCycle: [
    { key: 'k001', type: 0 },
    { key: 'k002', type: 1 },
    { key: 'k003', type: 2 },
  ],
  categories: [],
  contents: [],
  selectedCategory: 0,
  selectedConent: 0,
}

const getters = {
  categories(state) {
    return state.categories
  },
  contents(state) {
    return state.contents
  },
  pageType(state) {
    return state.contentsCycle[state.pageIndex].type
  },
  categoryName(state) {
    const category = state.categories.filter(row => {
      return row.id == state.selectedCategory
    })
    return category[0].name
  },
  categoryIcon(state) {
    const category = state.categories.filter(row => {
      return row.id == state.selectedCategory
    })
    return category[0].icon
  },
  contentData(state) {
    return state.contents[state.selectedConent]
  },
  contentIndex(state) {
    return state.selectedConent
  },
  contentLength(state) {
    return state.contents.length
  }
}

const mutations = {
  categories(state, payload) {
    state.categories = payload
  },
  contents(state, payload) {
    state.contents = payload
  },
  pageIndex(state, payload) {
    state.pageIndex = payload
  },
  selectedCategory(state, payload) {
    state.selectedCategory = payload
  },
  selectedConentIndex(state, payload) {
    state.selectedConent = payload
  }
}

const actions = {
  async loadCategories(context) {
    return await axios.get('/api/infomation/categories')
      .then(response => {
        context.commit('categories', response.data.categories)
        return
      })
  },
  async loadContentsByCategory(context, category) {
    context.commit('selectedCategory', category)
    return await axios.get(`/api/infomation/${category}/contents`)
      .then(response => {
        context.commit('contents', response.data.contents)
        return
      })
  },
  nextPage(context) {
    const index = context.state.pageIndex + 1
    context.commit('pageIndex', index)
  },
  prevPage(context) {
    const index = context.state.pageIndex - 1
    context.commit('pageIndex', index)
  },
  nextContent(context) {
    let index = context.state.selectedConent + 1
    if (index > context.state.contents.length - 1) index = context.state.contents.length - 1
    context.commit('selectedConentIndex', index)
  },
  prevContent(context) {
    let index = context.state.selectedConent - 1
    if (index < 0) index = 0
    context.commit('selectedConentIndex', index)
  },
  reset(context) {
    context.commit('pageIndex', 0)
    context.commit('selectedCategory', 0)
    context.commit('selectedConentIndex', 0)
  }
}

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
}
