/**
 * BufferLoader by Audio File
 */
export default class BufferLoader {
  constructor(context, dataSources, callback) {
    this.context = context;
    this.dataSources = dataSources;
    this.onloaded = callback;
    this.buffes = [];
    this.loadCount = 0;
  }

  loadBuffer(key, path, loop, index) {
    var _req = new XMLHttpRequest();    
    _req.open("GET", path, true);
    _req.responseType = "arraybuffer";

    var loader = this;
    _req.onload = function () {
        loader.context.decodeAudioData(
            _req.response,
            function (buffer) {
                if (!buffer) { return; }
                loader.buffes[index] = { key: key, buffer: buffer, status: 0, position: 0, source: null, gainNode: null, loop: loop };
                if (++loader.loadCount == loader.buffes.length) loader.onloaded(loader.buffes);
            },
            function (err) {
                console.error('decodeAudioData error', err);
            }
        );
    }
    
    _req.onerror = function () {
    }

    _req.send();
  }

  load() {
    for (var i = 0; i < this.dataSources.length; ++i) {
      this.loadBuffer(this.dataSources[i].key, this.dataSources[i].file, this.dataSources[i].loop, i);
    }
  }
}