import { createStore } from 'vuex'
import axios from 'axios'
import quiz from './modules/quiz'
import info from './modules/info'

const state = {
  account: null,
  quizzes: [],
  goods: {},
  deviceId: null,
  loading: false,
  isSmartPhone: false,
  isAgreed: false,
  isMute: true,
  settings: false,
  alert: null,
  overlay: 'loading',
  addedPoint: 0,
  isApp: false,
  unlocked: {
    ar: 0,
    game: 0,
  },
  version: require('../../package.json').version,
}

const getters = {
  version(state) {
    return state.version
  },

  unlocked(state) {
    return state.unlocked
  },

  isApp(state) {
    return state.isApp
  },

  deviceId(state) {
    return state.deviceId
  },

  account(state) {
    return state.account
  },

  isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  },

  isAgreed(state) {
    return state.isAgreed
  },

  overlay(state) {
    return state.overlay
  },

  point(state) {
    return state.account != null ? state.account.particle.point : 0
  },

  alertMessageText(state) {
    return state.alert
  },

  isSettings(state) {
    return state.settings
  },

  isMute(state) {
    return state.isMute
  },

  clearQuizLevel(state) {
    if (state.account == null || state.account.particle.clearlevel_id == null || typeof state.account.particle.clearlevel_id == 'undefined') return [];
    if (typeof state.account.particle.clearlevel_id == 'object') {
      return state.account.particle.clearlevel_id;
    } else {
      if (state.account.particle.clearlevel_id != null && state.account.particle.clearlevel_id.length > 0) {
        const json = JSON.parse(state.account.particle.clearlevel_id);
        return json
      }
      return []
    }
  },

  addedPoint(state) {
    return state.addedPoint
  },

  goods(state){
    return state.account != null && typeof state.account.particle.selected_goods != 'undefined' ? JSON.parse(state.account.particle.selected_goods) : [] 
  },

  rankText(state) {
    var texts = ["normal", "bronze", "silver", "gold", "platinum"];
    let rank = state.account != null ? state.account.particle.rank || 0 : 0;
    return texts[rank];
  },
}

const mutations = {
  isApp(state, payload) {
    state.isApp = payload
  },

  nickname(state, payload) {
    state.account.nickname = payload
  },

  deviceId(state, payload) {
    state.account.id = payload
    state.deviceId = payload
  },

  account(state, payload) {
    state.account = JSON.parse(JSON.stringify(payload))
  },

  point(state, payload) {
    state.account.particle.point = payload
  },

  message(state, payload) {
    state.alert = payload
  },

  overlay(state, payload) {
    state.overlay = payload
  },

  isSettings(state, payload) {
    state.settings = payload
  },

  isMute(state, payload) {
    state.isMute = payload
  },

  rank(state, payload) {
    state.account.particle.rank = payload
  },

  clearQuizId(state, payload) {
    state.account.particle.clearlevel_id = payload
  },

  addedPoint(state, payload) {
    state.addedPoint = payload
  },

  addGoods(state, payload){
    state.account.particle.selected_goods = payload
  },

  updateUnlocked(state, payload) {
    state.unlocked = payload
  },
}

const actions = {
  setupApp(context, payload) {
    context.commit('isApp', payload)
  },

  async createAccount(context, values) {
    return await axios.post('/api/device', values)
      .then(response => {
        context.commit('account', response.data)
        localStorage.setItem('deviceid', response.data.id)
      })
  },


  async loadAccount(context, id) {
    return await axios.get('/api/device/' + id)
      .then(response => {
        context.commit('account', response.data)
      })
  },

  async loadTransferAccount(context, id) {
    return await axios.get('/api/device/' + id)
      .then(response => {
        if (response.status == 200) {
          return Promise.resolve(response.data)
        } else {
          return Promise.reject()
        }
      })
  },

  async saveAccount(context, values) {
    return await axios.post(`/api/device/${context.state.account.id}`, values)
      .then(response => {
        context.commit('nickname', response.data.name)
      })
      
  },

  async transferAccount(context, payload) {
    const form = new FormData();
    form.append('code', payload);

    return await axios.post('/api/device/invitation', form)
      .then(response => {
        if (response.status == 200) {
          //context.commit('deviceId', response.data.uuid)
          //localStorage.setItem('deviceid', response.data.id)
          return Promise.resolve(response.data.uuid)
        } else {
          return Promise.reject()
        }
      })
  },

  async completTranser(context, id) {
    return await axios.get('/api/device/invitation/complet/' + id)
      .then(response => {
        console.log('completed')
      })
  },

  async saveParticle(context) {
    const form = new FormData();
    form.append('_method', 'put')
    form.append('particle_id', context.state.account.particle.id)
    form.append('clearlevel_id', context.state.account.particle.clearlevel_id || [])
    form.append('selected_goods', context.state.account.particle.selected_goods || [])
    form.append('rank', context.state.account.particle.rank)
    form.append('point', context.state.account.particle.point)
    form.append('characters', context.state.account.particle.characters || [])

    return await axios.post(`/api/device/${context.state.account.id}`, form)
      .then(response => {
        if (response.status == 200) return
      })
  },

  async loadCharactorDialogue(context, code) {
    return await axios.get('/api/dialogues/' + code)
  },

  showAlrt(context, message) {
    context.commit('message', message)
    setTimeout(() => {
      context.commit('message', null)
    }, 2000)
  },

  hiddenOverlay(context) {
    context.commit('overlay', null)
  },

  async showOverlay(context, value) {
    await context.commit('overlay', value)
  },

  openSettingsModal(context) {
    context.commit('isSettings', true)
  },

  closeSettingModal(context) {
    context.commit('isSettings', false)
  },

  async addPoint(context, value) {
    const point = context.state.account.particle.point + value
    const rank = context.state.account.particle.rank
    context.commit('point', point)
    context.commit('addedPoint', value)

    return new Promise(resolve => {
      setTimeout(() => {
        context.commit('addedPoint', 0)
        if (point >= 30 && rank == 0) {
          context.commit('rank', 1)
          context.commit('overlay', 'levelup')
        } else if (point >= 100 && rank == 1) {
          context.commit('rank', 2)
          context.commit('overlay', 'levelup')
        } else if (point >= 200 && rank == 2) {
          context.commit('rank', 3)
          context.commit('overlay', 'levelup')
        } else if (point >= 400 && rank == 3) {
          context.commit('rank', 4)
          context.commit('overlay', 'levelup')
        }
        resolve()
      }, 3500)
    })
  },

  subPoint(context, value) {
    context.commit('point', context.state.account.particle.point - value)
  },

  setClearQuiz(context, payload) {
    context.commit('clearQuizId', payload)
  },

  saveLocalStorage(context, payload) {
    localStorage.setItem(payload.key, payload.value)
  },

  resetLocalStorage(context) {
    localStorage.clear()
  },

  updateUnlocked(context, payload) {
    context.commit('updateUnlocked', payload)
  },
}

export default createStore({
  modules: {
    quiz: quiz,
    info: info,
  },
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
})